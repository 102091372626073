







































import Vue from "vue";

export default Vue.component("SOMenu", {
  components: {
    QTranslate: () => import("../elements/QTranslate.vue"),
    SOOrderListButton: () => import("./SOOrderListButton.vue")
  },
  data() {
    return {};
  },
  computed: {
    page(): string {
      try {
        return this.$route.path.split("/")[1];
      } catch (err) {
        return "";
      }
    }
  },
  methods: {
    tabClass(key: string) {
      if (key.match(this.page)) {
        return "active";
      }

      return "";
    },
    handleStop(e: Event) {
      e.preventDefault();

      return false;
    }
  }
});
